import React from 'react'

import { ExploreSplash, Gallery, Highlights, MasterFeatures, Platform, StandardHighlights } from 'components'
import { ExploreGallery } from 'helpers'
import { useMobileDetect } from 'hooks'

const Explore = () => {
  const { isMobile } = useMobileDetect()
  return (
    <main style={{ background: '#111', height: 'auto' }}>
      <ExploreSplash />
      <Highlights />
      <Platform isMobile={isMobile} />
      <Gallery rows={2} columns={4} images={ExploreGallery} backgroundSize='300px 300px' showGradient />
      <MasterFeatures isMobile={isMobile} />
      <StandardHighlights />
    </main>
  )
}

export default Explore
